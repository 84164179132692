@import 'react-medium-image-zoom/dist/styles.css';
@import 'swiper/swiper.min.css';
[data-rmiz-modal-content]{
    z-index: 10;
}

html{
    overflow-x: hidden;
}

body{
    width: 100%;
    overflow-x: hidden;
}

.grecaptcha-badge {
    visibility: hidden;
}

.w-inherit{
    width: inherit;
}

.img-cover img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

*:focus{
    outline: none !important;
}

